import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { t, tHtml } from "../../i18n/util";

type IProps = {
    uid: string;
    reportReleased: boolean;
    fullName: string;
    birthdate: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (uid: string, reportReleased: boolean) => void;
};

export const ReleaseReportConfirmDialog = ({
    uid,
    reportReleased,
    fullName,
    birthdate,
    open,
    onClose,
    onSubmit,
}: IProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleSubmit = () => {
        setIsSubmitting(true);
        onSubmit(uid, !reportReleased);
    };

    return (
        <Dialog
            PaperProps={{ "data-id": "release_report_dialog" } as any}
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            fullWidth
            disableBackdropClick
            maxWidth="sm"
        >
            <DialogTitle disableTypography id="dialog-title">
                <h3>
                    {t(
                        reportReleased
                            ? "screen.doctor.lock_report.dialog.title"
                            : "screen.doctor.release_report.dialog.title",
                    )}
                </h3>
            </DialogTitle>
            <DialogContent>
                <p>
                    {tHtml(
                        reportReleased
                            ? "screen.doctor.lock_report.dialog.confirmation_question"
                            : "screen.doctor.release_report.dialog.confirmation_question",
                        {
                            name: fullName,
                            birthdate: moment(birthdate).format("DD.MM.YYYY"),
                            b: str => <b>{str}</b>,
                        },
                    )}
                </p>
            </DialogContent>
            <DialogActions>
                <Button data-id="dialog_cancel" onClick={onClose}>
                    {t("dialog.button_cancel")}
                </Button>
                <Button
                    data-id="dialog_submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                >
                    {t(reportReleased ? "dialog.button_lock" : "dialog.button_release")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
