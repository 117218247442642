import { Dialog, DialogTitle, DialogContent, Button, DialogActions, withStyles } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "./CustomInputField";
import * as Yup from "yup";
import { API } from "../../network/API";
import { isEmail, isPhone } from "../util/Helper";
import styled from "styled-components";
import { generalStore } from "../../stores/GeneralStore";

type IProps = {
    uid: string;
    isOpen: boolean;
    onClose: () => void;
};

const Description = styled.p`
    margin-bottom: 20px;
`;

const FormGroupHeadline = styled.strong`
    margin-bottom: 15px;
    display: inline-block;
`;

const FormGroup = styled.div`
    display: flex;
`;

const LongTextButton = withStyles({
    root: {
        lineHeight: "normal",
        textAlign: "center",
    }
})(Button) as typeof Button

const FirstPage = ({ onNext, onClose }: { onNext: (values: { firstname: string; lastname: string }) => void; onClose: () => void }) => {
    return (
        <DialogContent>
            <Description>{t("add_doctor_dialog.description")}</Description>
            <Formik
                initialValues={{
                    firstname: "",
                    lastname: "",
                }}
                onSubmit={onNext}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string().required(t("add_doctor_dialog.form.firstname_error")),
                    lastname: Yup.string().required(t("add_doctor_dialog.form.lastname_error")),
                })}
                validateOnBlur={false}
                validateOnChange={true}
            >
                {({ errors, touched, isValid }) => (
                    <Form>
                        <FormGroupHeadline>{t("add_doctor_dialog.form_group.data.text")}</FormGroupHeadline>
                        <Field
                            data-id="add_doctor_firstname"
                            component={CustomInputField}
                            label={t("add_doctor_dialog.label.firstname")}
                            name="firstname"
                            type="text"
                            required
                            autoComplete="given-name"
                            isTouched={touched.firstname}
                            errorMessage={errors.firstname}
                            showValidationErrorText={true}
                        />
                        <Field
                            data-id="add_doctor_lastname"
                            component={CustomInputField}
                            label={t("add_doctor_dialog.label.lastname")}
                            name="lastname"
                            type="text"
                            errorMessage={errors.lastname}
                            required
                            autoComplete="family-name"
                            isTouched={touched.lastname}
                            showValidationErrorText={true}
                        />
                        <DialogActions>
                            <Button onClick={onClose} color="primary" size="large">
                                {t("confirm_resend_dialog.cancel.label")}
                            </Button>
                            <LongTextButton disabled={!isValid} type="submit" variant="contained" color="primary" size="large" autoFocus>
                                {t("add_doctor_dialog.form.next.text")}
                            </LongTextButton>
                            <LongTextButton
                                href={`/PGx_Informations_${generalStore.locale.toUpperCase()}.pdf`}
                                variant="contained"
                                color="primary"
                                target="_blank"
                                size="large"
                                onClick={onClose}
                            >
                                {t("add_doctor_dialog.download_informations_pdf")}
                            </LongTextButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </DialogContent>
    );
};

const SecondPage = ({ onSubmit, onClose }: { onSubmit: (values: { zip?: string; city: string; email?: string; phone: string }) => Promise<void>; onClose: () => void }) => {
    return (
        <DialogContent>
            <Description>{t("add_doctor_dialog.description")}</Description>
            <Formik
                initialValues={{
                    zip: "",
                    city: "",
                    email: "",
                    phone: "",
                }}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    zip: Yup.string(),
                    city: Yup.string().required(t("add_doctor_dialog.form.city_error")),
                    email: Yup.string().test(
                        "is valid email",
                        t("add_doctor_dialog.form.mail_error"),
                        value => !value || isEmail(value),
                    ),
                    phone: Yup.string()
                        .required(t("add_doctor_dialog.form.phone_error"))
                        .test("is valid phone", t("add_doctor_dialog.form.phone_error"), isPhone),
                })}
                validateOnBlur={false}
                validateOnChange={true}
            >
                {({ errors, touched, isValid }) => (
                    <Form>
                        <FormGroupHeadline>{t("add_doctor_dialog.form_group.contact_data.text")}</FormGroupHeadline>
                        <FormGroup>
                            <Field
                                data-id="add_doctor_zip"
                                component={CustomInputField}
                                label={t("add_doctor_dialog.label.zip")}
                                name="zip"
                                type="text"
                                errorMessage={errors.zip}
                                autoComplete="postal-code"
                                isTouched={touched.zip}
                                size="8"
                                showValidationErrorText={true}
                                style={{ flexGrow: 2, marginRight: 15 }}
                            />
                            <Field
                                data-id="add_doctor_city"
                                component={CustomInputField}
                                label={t("add_doctor_dialog.label.city")}
                                name="city"
                                type="text"
                                required
                                errorMessage={errors.city}
                                autoComplete="address-level2"
                                isTouched={touched.city}
                                showValidationErrorText={true}
                                style={{ flexGrow: 4 }}
                            />
                        </FormGroup>
                        <Field
                            data-id="add_doctor_phone"
                            component={CustomInputField}
                            label={t("add_doctor_dialog.label.phone")}
                            name="phone"
                            type="text"
                            required
                            errorMessage={errors.phone}
                            autoComplete="tel"
                            isTouched={touched.phone}
                            showValidationErrorText={true}
                        />
                        <Field
                            data-id="add_doctor_email"
                            component={CustomInputField}
                            label={t("add_doctor_dialog.label.email")}
                            name="email"
                            type="text"
                            errorMessage={errors.email}
                            autoComplete="email"
                            isTouched={touched.email}
                            showValidationErrorText={true}
                        />
                        <Description>{t("add_doctor_dialog.confirmation_text")}</Description>
                        <DialogActions>
                            <Button onClick={onClose} color="primary" size="large">
                                {t("confirm_resend_dialog.cancel.label")}
                            </Button>
                            <Button disabled={!isValid} type="submit" variant="contained" color="primary" size="large" autoFocus>
                                {t("add_doctor_dialog.form.submit.text")}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </DialogContent>
    );
};

export const AddDoctorDialog = ({ isOpen, onClose, uid }: IProps) => {
    const [values, setValues] = React.useState({
        firstname: "",
        lastname: "",
    });
    const [page, setPage] = React.useState(0);

    const _onClose = () => {
        onClose();

        setValues({
            firstname: "",
            lastname: "",
        });

        setPage(0);
    };

    const _onNext = (inputs: { firstname: string; lastname: string }) => {
        if (inputs) {
            setValues(inputs);
            setPage(1);
        }
    };

    const _onSubmit = async (inputs: { zip?: string; city: string; email?: string; phone: string }) => {
        if (inputs) {
            try {
                const result = await API.postDoctorSuggestion(
                    {
                        ...inputs,
                        ...values,
                    },
                    uid,
                );

                if (result && result.success) {
                    _onClose();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md">
            <DialogTitle id="alert-dialog-title">{t("add_doctor_dialog.headline")}</DialogTitle>
            {page === 0 && <FirstPage onNext={_onNext} onClose={_onClose} />}
            {page === 1 && <SecondPage onSubmit={_onSubmit} onClose={_onClose} />}
        </Dialog>
    );
};
