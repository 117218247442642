import { Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { Route, RouteChildrenProps } from "react-router";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { optimizerStore } from "../../../stores/OptimizerStore";
import { patientStore } from "../../../stores/PatientStore";
import { StandardContainer } from "../../containers/StandardContainer";
import { Routes } from "../../routers/Routes";
import { NavigationTab } from "../../ui/NavigationTab";
import { UserNavigation } from "../../ui/UserNavigation";
import { ManualOptimizeSite } from "../doctor/ManualOptimizeSite";
import { MedicationAnalysisSite } from "../doctor/MedicationAnalysisSite";
import { MedicationGroups } from "../shared/components/MedicationGroups";
import { ReportSite } from "./ReportSite";
import { NotFoundSite } from "../NotFoundSite";
import { IPatient } from "../../../types";

const siteToIndex = (tab?: string) => {
    switch (tab) {
        case Routes.PATIENT.MEDICATION_GROUPS:
            return 1;
        case Routes.PATIENT.MEDICATION_CHECK:
        case Routes.PATIENT.OPTIMIZE:
            return 2;
        case Routes.PATIENT.REPORT:
        default:
            return 0;
    }
};

type IProps = RouteChildrenProps<{}>;

export const PatientSite = observer(({ location }: IProps) => {
    const tab = location.pathname;
    const siteIndex = siteToIndex(tab);
    const isPatientPlus = authStore.isPatientPlus;
    const reportReleased = (authStore.userProfile?.user as IPatient).reportReleased;

    const singleMedGroups = optimizerStore.substances?.singleMedGroups;

    React.useEffect(() => {
        const getMedicationGroups = async () => {
            if (singleMedGroups) {
                await patientStore.loadGroups();
            }
        };

        getMedicationGroups();
    }, [singleMedGroups]);

    // Wait for rehydration and loadSubstances in order to be able to show the correct tabs for the patient, flashing Medicationcheck tab possible otherwise
    if (!optimizerStore.isRehydrated || optimizerStore.isLoading) {
        return null;
    }

    const isMedicationCheckOrOptimizeSite =
        location.pathname.startsWith(Routes.PATIENT.MEDICATION_CHECK) ||
        location.pathname.startsWith(Routes.PATIENT.OPTIMIZE);
    const isMedicationGroupsSite = location.pathname.startsWith(Routes.PATIENT.MEDICATION_GROUPS);

    if (
        (!isPatientPlus && isMedicationCheckOrOptimizeSite) ||
        (!reportReleased && (isMedicationCheckOrOptimizeSite || isMedicationGroupsSite))
    ) {
        return <NotFoundSite />;
    }

    return (
        <>
            <UserNavigation
                leftComponent={
                    <Tabs value={siteIndex} aria-label="nav tabs">
                        <NavigationTab
                            data-id="navigation_report"
                            label={t("screen.patient.navigation.tab.report")}
                            to={Routes.PATIENT.REPORT}
                        />
                        {reportReleased && (
                            <>
                                <NavigationTab
                                    data-id="navigation_medication_groups"
                                    label={t("screen.patient.navigation.tab.medication_groups")}
                                    to={Routes.PATIENT.MEDICATION_GROUPS}
                                />
                                {isPatientPlus &&
                                patientStore.showMedicationCheck && ( // showMedicationCheck can only be calculated after substances are loaded/rehydrated
                                        <NavigationTab
                                            data-id="navigation_medication_check"
                                            label={t("screen.patient.navigation.tab.medication_check")}
                                            to={Routes.PATIENT.MEDICATION_CHECK}
                                        />
                                    )}
                            </>
                        )}
                    </Tabs>
                }
            />
            <StandardContainer>
                <Route exact path={Routes.PATIENT.ROOT} component={ReportSite} />
                <Route path={Routes.PATIENT.REPORT} component={ReportSite} />
                {reportReleased && (
                    <>
                        <Route path={Routes.PATIENT.MEDICATION_GROUPS} component={MedicationGroups} />
                        {isPatientPlus && (
                            <>
                                <Route path={Routes.PATIENT.MEDICATION_CHECK} component={MedicationAnalysisSite} />
                                <Route path={Routes.PATIENT.OPTIMIZE} component={ManualOptimizeSite} />
                            </>
                        )}
                    </>
                )}
            </StandardContainer>
        </>
    );
});
