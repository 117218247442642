import { Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { pick } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { t } from "../../../../i18n/util";
import { generalStore } from "../../../../stores/GeneralStore";
import { IDoctor } from "../../../../types";
import { IDoctorValues } from "../../../forms/DoctorForm";
import { ConfirmEmailDialog } from "../../../ui/ConfirmResendDialog";
import { EmailStatus } from "../../../ui/EmailStatus";
import { SortableTableCell } from "../../../ui/SortableTableCell";
import { TableActionsContainer } from "../../../ui/TableActionsContainer";
import { Colors } from "../../../util/Colors";
import { copyTextToClipboard } from "../../../util/copyTextToClipboard";
import { Icon } from "../../../util/Icon";
import { MfaToggle } from "./MfaToggle";

type IProps = {
    rows: IDoctor[];
    onClickEditUser: (doctor: IDoctorValues, uid: string) => void;
    onClickDeleteUser: (user: IDoctorValues, uid: string) => void;
    onClickResendEmail: (uid: string) => void;
    onChangeSort: (column: string) => void;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    enableMfaForUser: (userId: string) => () => void;
    disableMfaForUser: (userId: string) => () => void;
};

const getHeaderCells = () => [
    { column: "lastname", label: t("table.heading.lastname") },
    { column: "firstname", label: t("table.heading.firstname") },
    { column: "pharmgenetixId", label: t("table.heading.pharmgenetix_id") },
    { column: "lastLoginAt", label: t("table.heading.last_active_at") },
    { column: "username", label: t("table.heading.email") },
    {
        label: (
            <span>
                {t("table.heading.reports")}
                <span style={{ marginLeft: 8, fontWeight: "normal", textTransform: "none" }}>
                    {t("table.heading.reports_in_process_and_total")}
                </span>
            </span>
        ),
    },
    { label: null },
];

export const DoctorsTable = ({
    rows,
    onClickEditUser,
    onClickResendEmail,
    onChangeSort,
    orderBy,
    orderDirection,
    onClickDeleteUser,
    enableMfaForUser,
    disableMfaForUser,
}: IProps) => {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLLIElement) | null>(null);
    const [doctor, setDoctor] = useState<IDoctor>();
    const [selectedUID, setSelectedUID] = useState<string>();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({ title: "", actionText: "" });

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = () => {
        if (doctor) {
            handleClose();

            setTimeout(() => {
                const copyText = doctor.passwordResetLink;
                copyTextToClipboard(copyText);
                generalStore.successMessage = t("success.copy");
            }, 300);
        }
    };

    const handleConfirmOpen = (uid: string, title: string, actionText: string) => {
        setSelectedUID(uid);
        setIsConfirmationModalOpen(true);
        setConfirmDialogProps({ title, actionText });
    };

    const handleConfirmClose = () => {
        setIsConfirmationModalOpen(false);
    };

    return (
        <div style={{ overflow: "auto" }}>
            <ConfirmEmailDialog
                isOpen={isConfirmationModalOpen}
                onClose={handleConfirmClose}
                action={() => {
                    if (selectedUID) {
                        onClickResendEmail(selectedUID);
                        setIsConfirmationModalOpen(false);
                    }
                }}
                {...confirmDialogProps}
            />
            <Table data-id="doctors_table" aria-label="table">
                <TableHead>
                    <TableRow>
                        {getHeaderCells().map((header, index) => (
                            <SortableTableCell
                                key={`${header.column}_${index}`}
                                column={header.column}
                                label={header.label}
                                orderBy={orderBy}
                                orderDirection={orderDirection}
                                onChangeSort={onChangeSort}
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow data-id={`row_${index + 1}`} key={row.uid}>
                            <TableCell data-id={"lastname"}>{row.lastname}</TableCell>
                            <TableCell data-id={"firstname"}>{row.firstname}</TableCell>
                            <TableCell data-id={"pharmgenetix_id"}>{row.pharmgenetixId}</TableCell>
                            <TableCell data-id={"last_login_at"}>
                                {row.lastLoginAt ? moment(row.lastLoginAt).format("DD.MM.YYYY") : ""}
                            </TableCell>
                            <TableCell data-id={"username"}>{row.username}</TableCell>
                            <TableCell data-id={"report"}>
                                {row.reportsReleased?.released || 0} / {row.reportsReleased?.total || 0}
                            </TableCell>
                            <TableCell data-id={"actions"} align="right">
                                <TableActionsContainer>
                                    <MfaToggle
                                        mfaEnabled={row.mfaEnabled}
                                        enableMfa={enableMfaForUser(row.uid)}
                                        dissableMfa={disableMfaForUser(row.uid)}
                                    />
                                    {row.status === "emailSent" && (
                                        <div style={{ position: "relative" }}>
                                            <Icon
                                                data-id="resend_invitation_mail"
                                                name="email"
                                                title={t("table.action.send_invitation.title")}
                                                hoverColor={Colors.secondary}
                                                onClick={() =>
                                                    handleConfirmOpen(
                                                        row.uid,
                                                        t("confirm_resend_dialog.headline.text"),
                                                        t("confirm_resend_dialog.resend_password.label"),
                                                    )
                                                }
                                            />
                                            <EmailStatus status={row.emailStatus || "blue"} />
                                        </div>
                                    )}
                                    {row.status === "confirmed" && (
                                        <Icon
                                            data-id="reset_password"
                                            name="password"
                                            title={t("table.action.reset_password.title")}
                                            hoverColor={Colors.secondary}
                                            onClick={() =>
                                                handleConfirmOpen(
                                                    row.uid,
                                                    t("confirm_reset_dialog.headline.text"),
                                                    t("confirm_reset_dialog.label"),
                                                )
                                            }
                                        />
                                    )}
                                    <Icon
                                        data-id="edit"
                                        name="edit"
                                        title={t("table.action.edit.title")}
                                        hoverColor={Colors.secondary}
                                        onClick={() =>
                                            onClickEditUser(
                                                pick(row, [
                                                    "firstname",
                                                    "lastname",
                                                    "language",
                                                    "pharmgenetixId",
                                                    "username",
                                                ]),
                                                row.uid,
                                            )
                                        }
                                    />
                                    {row.passwordResetLink ? (
                                        <Icon
                                            data-id="copy"
                                            name="copy"
                                            title={t("table.action.copy.title")}
                                            hoverColor={Colors.secondary}
                                            onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                                                setDoctor(row);
                                                handleClick(e);
                                            }}
                                        />
                                    ) : (
                                        <div style={{ width: 24 }}></div>
                                    )}
                                    <Icon
                                        data-id="delete"
                                        name="trash"
                                        color={Colors.danger}
                                        title={t("table.action.delete.doctor")}
                                        hoverColor={Colors.secondary}
                                        onClick={() => {
                                            onClickDeleteUser(row, row.uid);
                                        }}
                                    />
                                </TableActionsContainer>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {doctor?.passwordResetLink ? (
                    <MenuItem onClick={() => handleMenuItemClick()}>
                        {t("table.action.copy.passwordResetLink")}
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    );
};
