import { Button, Table, TableBody, TableCell, TableRow, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { IPatient } from "../../../types";
import { fetchFile } from "../../util/fetchFile";
import { Icon } from "../../util/Icon";
import { Colors } from "../../util/Colors";
import { AddDoctorDialog } from "../../ui/AddDoctorDialog";

const Container = styled.div`
    display: flex;
    justify-content: center;
    & > div + div {
        margin-left: 60px;
    }
`;

const Column = styled.div`
    flex-basis: 0;
    flex-grow: 1;
`;

const Heading = styled.h3`
    margin-bottom: 30px;
`;

const Section = styled.div`
    margin-bottom: 60px;
`;

const SectionText = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
`;

const ReportDownloadContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
`;

const AddDoctorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
`;

const AddDoctorText = styled.p`
    font-size: 14;
    color: #878787;
`;

const DateContainer = styled.div`
    font-size: 14px;
    color: #878787;
`;

const LabelCell = withStyles({
    root: {
        fontSize: 14,
        color: "#878787",
        padding: 0,
        width: 235,
    },
})(TableCell);

const DataCell = withStyles({
    root: {
        padding: 0,
    },
})(TableCell);

export const ReportSite = observer(() => {
    const [isAddDoctorModalOpen, setIsAddDoctorModalOpen] = React.useState(false);
    React.useEffect(() => {
        authStore.refreshUserProfile();
    }, []);

    const {
        uid,
        firstname,
        lastname,
        birthdate,
        pharmgenetixId,
        username,
        canOptimize,
        doctor,
        originalPdfName,
        reportReleased,
        reportDate,
    } = authStore.userProfile?.user as IPatient;

    const doctorRows = doctor.map(doc => (
        <TableRow key={doc.uid}>
            <DataCell data-id="doctor">
                {doc.firstname} {doc.lastname}
            </DataCell>
            <LabelCell align="right">
                {doctor.length > 1 ? (
                    <Icon
                        name="trash"
                        hoverColor={Colors.secondary}
                        title={t("table.action.delete.title")}
                        onClick={() => {
                            deleteDoctor(doc.uid);
                            return;
                        }}
                    />
                ) : null}
            </LabelCell>
        </TableRow>
    ));

    const deleteDoctor = async (doctorUid: string) => {
        try {
            const response = await API.deleteDoctorFromPatient(uid, [doctorUid]);
            if (response) {
                authStore.refreshUserProfile();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container>
            <Column>
                <Heading>{t("screen.patient.report.heading.core_data")}</Heading>
                <Section>
                    <SectionText>{t("screen.patient.report.section.personal_data")}</SectionText>
                    <Table data-id="core_data_table">
                        <TableBody>
                            <TableRow>
                                <LabelCell>{t("screen.patient.report.first_and_lastname")}</LabelCell>
                                <DataCell data-id="name">
                                    {lastname} {firstname}
                                </DataCell>
                            </TableRow>
                            <TableRow>
                                <LabelCell>{t("screen.patient.report.birthdate")}</LabelCell>
                                <DataCell data-id="birthdate">{moment(birthdate).format("DD.MM.YYYY")}</DataCell>
                            </TableRow>
                            <TableRow>
                                <LabelCell>{t("screen.patient.report.pharmgenetix_id")}</LabelCell>
                                <DataCell data-id="pharmgenetix_id">{pharmgenetixId}</DataCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Section>
                <Section>
                    <SectionText>{t("screen.patient.report.section.contact")}</SectionText>
                    <Table data-id="contact_table">
                        <TableBody>
                            <TableRow>
                                <LabelCell>{t("screen.patient.report.email")}</LabelCell>
                                <DataCell data-id="email">{username}</DataCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Section>
            </Column>
            <Column>
                <Heading>{t("screen.patient.report.heading.analysis_and_report")}</Heading>
                <Section>
                    <SectionText>{t("screen.patient.report.section.implemented_test")}</SectionText>
                    <Table data-id="analysis_and_report_table">
                        <TableBody>
                            <TableRow>
                                <DataCell data-id="plan">
                                    {canOptimize ? t("common.plan.totalCare") : t("common.plan.basic")}
                                </DataCell>
                                <LabelCell align="right">{t("screen.patient.report.report_at_doctor")}</LabelCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {reportReleased && originalPdfName && (
                        <ReportDownloadContainer>
                            <Icon
                                name="pdf"
                                size={60}
                                style={{ marginRight: 16 }}
                                onClick={async () => {
                                    try {
                                        const report = await API.getPdfReport(uid);

                                        fetchFile(report, true, originalPdfName);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                            />
                            <div>
                                <b>{originalPdfName}</b>
                                <DateContainer>{reportDate && moment(reportDate).format("DD.MM.YYYY")}</DateContainer>
                            </div>
                        </ReportDownloadContainer>
                    )}
                </Section>
                <Section>
                    <AddDoctorDialog
                        uid={uid}
                        isOpen={isAddDoctorModalOpen}
                        onClose={() => {
                            setIsAddDoctorModalOpen(false);
                        }}
                    />
                    <SectionText>{t("screen.patient.report.unlocked_doctors")}</SectionText>
                    <Table data-id="analysis_and_report_table">
                        <TableBody>{doctorRows}</TableBody>
                    </Table>
                    <AddDoctorContainer>
                        <AddDoctorText>{t("screen.patient.report.unlock_data")}</AddDoctorText>
                        <Button onClick={() => setIsAddDoctorModalOpen(true)}>
                            {t("screen.patient.report.add_doctor")}
                        </Button>
                    </AddDoctorContainer>
                </Section>
            </Column>
        </Container>
    );
});
