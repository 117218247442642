import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { t } from "../../../../i18n/util";
import { IDoctor, INewsValues } from "../../../../types";
import { IDoctorValues } from "../../../forms/DoctorForm";
import { Icon } from "../../../util/Icon";
import { SortableTableCell } from "../../../ui/SortableTableCell";
import { Colors } from "../../../util/Colors";
import styled from "styled-components";

type IProps = {
    rows: INewsValues[];
    onClickEditNews: (values: INewsValues) => void;
    onClickDeleteNews: (values: INewsValues) => void;
    onChangeSort: (direction: "asc" | "desc") => void;
    orderDirection?: "asc" | "desc";
};

const getHeaderCells = () => [
    { column: "date", label: t("news.date") },
    { column: "title", label: t("news.title") },
    { column: "content", label: t("news.content") },
    { column: "patientEnabled", label: t("news.patientEnabled") },
    { column: "doctorEnabled", label: t("news.doctorEnabled") },
    { column: "edit", label: "" },
];

const ActionTableCell = withStyles({
    root: {
        textAlign: "right",
        minWidth: 150,
    },
})(TableCell);

const Spacer = styled.div`
    display: inline-block;
    width: 20px;
`;

export const NewsTable = ({ rows = [], onChangeSort, orderDirection, onClickEditNews, onClickDeleteNews }: IProps) => {
    const handleChangeSort = () => {
        const newDirection = orderDirection === "asc" ? "desc" : "asc";
        onChangeSort(newDirection);
    };
    return (
        <div style={{ overflow: "auto" }}>
            <Table data-id="doctors_table" aria-label="table">
                <TableHead>
                    <TableRow>
                        {getHeaderCells().map((header, index) => (
                            <SortableTableCell
                                key={`${header.column}_${index}`}
                                column={header.column}
                                label={header.label}
                                orderBy={"date"}
                                orderDirection={orderDirection}
                                onChangeSort={handleChangeSort}
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: INewsValues, index) => (
                        <TableRow data-id={`row_${index + 1}`} key={row.uid} className="news-table">
                            <TableCell data-id={"date"}>{moment(row.showAfter).format("DD.MM.YYYY")}</TableCell>
                            <TableCell data-id={"title"}>{row.title}</TableCell>
                            <TableCell data-id={"content"}>
                                <div dangerouslySetInnerHTML={{ __html: row.content }} />
                            </TableCell>
                            <TableCell>
                                <Icon
                                    name={row.patientEnabled ? "check" : "x"}
                                    title={row.patientEnabled ? t("news.published") : t("news.unpublished")}
                                    color={Colors.primary}
                                />
                            </TableCell>
                            <TableCell>
                                <Icon
                                    name={row.doctorEnabled ? "check" : "x"}
                                    title={row.doctorEnabled ? t("news.published") : t("news.unpublished")}
                                    color={Colors.primary}
                                />
                            </TableCell>
                            <ActionTableCell data-id={"edit"}>
                                <Icon
                                    data-id="edit"
                                    name="edit"
                                    title={t("table.action.edit.title")}
                                    hoverColor={Colors.secondary}
                                    onClick={() => onClickEditNews(row)}
                                />
                                <Spacer />
                                <Icon
                                    color={Colors.danger}
                                    data-id="delete"
                                    name="trash"
                                    title={t("table.action.edit.title")}
                                    hoverColor={Colors.secondary}
                                    onClick={() => onClickDeleteNews(row)}
                                />
                            </ActionTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
