import { IconButton, InputAdornment } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FieldInputProps } from "formik";
import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { PasswordStrengthChecker } from "./PasswordStrengthChecker";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    showPasswordChecker: boolean;
    "data-id"?: string;
    size?: number;
};

export const CustomInputField = ({
    style,
    label,
    type,
    autoComplete,
    required,
    errorMessage,
    field,
    isTouched,
    rows,
    size,
    showValidationErrorText = true,
    showPasswordChecker,
    multiline = false,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const showError = isTouched && !!errorMessage;

    const handleToggleShowPassword = () => setShowPassword(prevState => !prevState);

    const passwordProps =
        type === "password"
            ? {
                  InputProps: {
                      endAdornment: (
                          <InputAdornment position="end">
                              <Icon
                                  name={showPassword ? "passwordHidden" : "passwordVisible"}
                                  onClick={handleToggleShowPassword}
                              />
                          </InputAdornment>
                      ),
                  },
              }
            : {};

    const passwordType = showPassword ? "text" : "password";

    return (
        <div style={style}>
            <TextField
                label={required ? `${label} *` : label}
                value={field.value || ""}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                fullWidth
                multiline={multiline}
                type={type === "password" ? passwordType : type}
                autoComplete={autoComplete}
                error={showError}
                aria-label={ariaLabel}
                variant="outlined"
                rows={rows}
                inputProps={{ "data-id": dataId, size }}
                {...passwordProps}
            />
            {showPasswordChecker ? <PasswordStrengthChecker password={field.value || ""} /> : null}
            {showValidationErrorText && (
                <span
                    data-id={`${dataId}_error`}
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
