import * as React from "react";
import { CenteredContainer } from "../containers/CenteredContainer";
import Button from "@material-ui/core/Button";
import * as Config from "../../config";

export function ManualsSite() {
    return (
        <CenteredContainer>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/doctor_de.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Arzt_DE
            </Button>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/doctor_en.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Arzt_EN
            </Button>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/doctor_it.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Arzt_IT
            </Button>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/patient_de.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Patient_DE
            </Button>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/patient_en.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Patient_EN
            </Button>
            <Button
                href={`${Config.CDN_URL}/ifus/phgx/patient_it.pdf`}
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gebrauchsanweisung Patient_IT
            </Button>
        </CenteredContainer>
    );
}
