import {
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { optimizerStore } from "../../../../stores/OptimizerStore";
import { IMedication, ISubstance, ISubstanceId } from "../../../../types";
import { BadgeCollection } from "../../../ui/BadgeCollection";
import { Badge } from "../../../util/Badge";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { getTranslation } from "../../../util/Optimizer";
import { CustomDialog } from "../../../dialogs/CustomDialog";
import { FormattedHTMLMessage } from "react-intl";
import { authStore } from "../../../../stores/AuthStore";

const FlexHeight = withStyles({
    root: {
        flexBasis: 0,
        flexGrow: 1,
    },
})(TableContainer);

const StyledCheckbox = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent !important",
        },
    },
})(Checkbox);

const FixedBadgeBase = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    line-height: 12px;
    color: #ffffff;
    background-color: ${Colors.primary};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.5px;
    border-radius: 14px;
    width: 72px;
    cursor: pointer;
    user-select: none;
`;

const FixedBadge = styled(FixedBadgeBase)`
    padding-right: 5px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: ${Colors.secondary};
    }
`;

const FixableBadge = styled(FixedBadgeBase)`
    color: ${Colors.primary};
    background-color: #f9f9f9;
    padding-right: 0;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        color: ${Colors.secondary};
        background-color: #f7f7f7;
    }
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * + * {
        margin-left: 16px;
    }
`;

type IProps = {
    data: IMedication;
    fixed: ISubstanceId[];
    fixable?: boolean;
    onToggleFixSubstance: (substanceId: ISubstanceId) => void;
    onClickRemoveMedication: (substanceId: ISubstanceId) => void;
    onClickReplaceManually: (medication: ISubstance) => void;
};

const ReplaceSubstanceAction = ({
    substance,
    onClickReplaceManually,
}: {
    substance: ISubstance;
    onClickReplaceManually: (medication: ISubstance) => void;
}) => {
    return optimizerStore.substances?.atcCodes[substance.id] ? (
        <Icon name="replace" hoverColor={Colors.secondary} onClick={onClickReplaceManually(substance)} />
    ) : (
        <Icon name="replace" color="#878787" disabled />
    );
};

export const InteractionsTable = observer(
    ({ data, fixed, fixable, onClickRemoveMedication, onClickReplaceManually, onToggleFixSubstance }: IProps) => {
        const userIsPatient = authStore.isPatientPlus;
        const [substanceToReplace, setSubstanceToReplace] = React.useState<ISubstance | null>(null);
        const [replaceWarningApproved, setReplaceWarningApproved] = React.useState(false);

        const handleChangeReplaceWarningApproval = (event: React.ChangeEvent<HTMLInputElement>) => {
            setReplaceWarningApproved(event.target.checked);
        };

        const handleSubmitReplaceWarningDialog = () => {
            if (substanceToReplace) {
                onClickReplaceManually(substanceToReplace);
            }
            setSubstanceToReplace(null);
        };

        const handleCloseReplaceWarningDialog = () => {
            setSubstanceToReplace(null);
            setReplaceWarningApproved(false);
        };
        const handleClickReplaceManually = (substance: ISubstance) => () => {
            if (userIsPatient) {
                setSubstanceToReplace(substance);
            } else {
                onClickReplaceManually(substance);
            }
        };

        return (
            <>
                <FlexHeight>
                    <Table data-id="interactions_table" aria-label="table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("table.heading.active_agent")}</TableCell>
                                <TableCell>{t("table.heading.single_medication")}</TableCell>
                                <TableCell>{t("table.heading.interaction")}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.medication.map((substance, index) => (
                                <TableRow data-id={`row_${index + 1}`} key={substance.id}>
                                    <TableCell data-id="active_agent">
                                        <b>{getTranslation(substance.id)}</b>
                                    </TableCell>
                                    <TableCell data-id="single_medication">
                                        <BadgeCollection>
                                            <Badge value={substance.single.fc} />
                                            {substance.single.warnTL && (
                                                <Badge
                                                    value="warning"
                                                    color="gray"
                                                    tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                                />
                                            )}
                                        </BadgeCollection>
                                    </TableCell>
                                    <TableCell data-id="interaction">
                                        <BadgeCollection>
                                            <Badge value={substance.interaction?.fc} />
                                            {substance.single.warnTL && (
                                                <Badge
                                                    value="warning"
                                                    color="gray"
                                                    tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                                />
                                            )}
                                        </BadgeCollection>
                                    </TableCell>
                                    <TableCell data-id="actions" align="right">
                                        <ActionContainer>
                                            <Icon
                                                name="trash"
                                                hoverColor={Colors.secondary}
                                                title={t("table.action.delete.title")}
                                                onClick={() => {
                                                    if (substance.id) {
                                                        onClickRemoveMedication(substance.id);
                                                    }
                                                }}
                                            />
                                            {fixable && substance.id && (
                                                <>
                                                    {fixed.includes(substance.id) ? (
                                                        <FixedBadge
                                                            onClick={() => {
                                                                if (substance.id) {
                                                                    onToggleFixSubstance(substance.id);
                                                                }
                                                            }}
                                                        >
                                                            <Icon size={16} name="check" />
                                                            {t("table.action.fixed")}
                                                        </FixedBadge>
                                                    ) : (
                                                        <FixableBadge
                                                            onClick={() => {
                                                                if (substance.id) {
                                                                    onToggleFixSubstance(substance.id);
                                                                }
                                                            }}
                                                        >
                                                            {t("table.action.fix")}
                                                        </FixableBadge>
                                                    )}
                                                </>
                                            )}
                                            <ReplaceSubstanceAction
                                                substance={substance}
                                                onClickReplaceManually={handleClickReplaceManually}
                                            />
                                        </ActionContainer>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </FlexHeight>
                {userIsPatient && substanceToReplace && (
                    <CustomDialog
                        open
                        onSubmit={handleSubmitReplaceWarningDialog}
                        onClose={handleCloseReplaceWarningDialog}
                        maxWidth="md"
                        disableButton={!replaceWarningApproved}
                        title={t("patient.replaceWarningDialog.title")}
                        message={
                            <>
                                <FormattedHTMLMessage id="patient.replaceWarningDialog.text" />
                                <br />
                                <br />
                                <FormControlLabel
                                    control={
                                        <StyledCheckbox
                                            disableRipple
                                            checked={replaceWarningApproved}
                                            onChange={handleChangeReplaceWarningApproval}
                                        />
                                    }
                                    label={t("patient.replaceWarningDialog.approval.text")}
                                />
                            </>
                        }
                    />
                )}
            </>
        );
    },
);
