import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Colors } from "../../../util/Colors";
import { t } from "../../../../i18n/util";

// icons downloaded from https://fonts.google.com/icons
export function MfaToggle({
    mfaEnabled,
    enableMfa,
    dissableMfa,
}: {
    mfaEnabled: boolean;
    enableMfa: () => void;
    dissableMfa: () => void;
}) {
    if (mfaEnabled) {
        return (
            <IconButton onClick={dissableMfa} color="primary" aria-label={t("mfa.button_label_enabled")}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="27px"
                    viewBox="0 -960 960 960"
                    width="27px"
                    fill={Colors.primary}
                >
                    <title>{t("mfa.button_label_enabled")}</title>
                    <path d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Zm-80 160h160q17 0 28.5-11.5T600-360v-120q0-17-11.5-28.5T560-520v-40q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560v40q-17 0-28.5 11.5T360-480v120q0 17 11.5 28.5T400-320Zm40-200v-40q0-17 11.5-28.5T480-600q17 0 28.5 11.5T520-560v40h-80Z" />
                </svg>
            </IconButton>
        );
    }

    return (
        <IconButton onClick={enableMfa} color="primary" aria-label={t("mfa.button_label_disabled")}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="27px"
                viewBox="0 -960 960 960"
                width="27px"
                fill={Colors.danger}
            >
                <title>{t("mfa.button_label_disabled")}</title>
                <path d="M480-320q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm-40-160h80v-200h-80v200Zm40 400q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z" />
            </svg>
        </IconButton>
    );
}
