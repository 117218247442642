import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { CenteredContainer } from "../containers/CenteredContainer";
import { LoginForm, ILoginValues } from "../forms/LoginForm";
import { VerificationForm } from "../forms/VerificationForm";

export const LoginSite = observer(() => {
    const [error, setError] = React.useState("");

    const handleSubmit = async (model: ILoginValues) => {
        generalStore.isLoading = true;
        setError("");

        try {
            await authStore.loginWithPassword(model.email, model.password);

            if (authStore.error) {
                if (authStore.error === "PasswordWrong") {
                    setError(t("screen.login.invalid_password_or_email"));
                } else if (authStore.error === "Unknown") {
                    setError(t("screen.login.error_during_login"));
                }
            }
        } catch (error) {
            setError(t("screen.login.error_during_login"));
        }

        generalStore.isLoading = false;
    };

    if (!authStore.isRehydrated) {
        return null;
    }

    if (authStore.verificationRequired) {
        return (
            <CenteredContainer>
                <VerificationForm />
            </CenteredContainer>
        );
    }

    return (
        <CenteredContainer>
            <LoginForm error={error} onSubmit={handleSubmit} />
        </CenteredContainer>
    );
});
