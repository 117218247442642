import {
    Button,
    createMuiTheme,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { t } from "../../../../i18n/util";
import { generalStore } from "../../../../stores/GeneralStore";
import { IPatient } from "../../../../types";
import { Routes } from "../../../routers/Routes";
import { SortableTableCell } from "../../../ui/SortableTableCell";
import { TableActionsContainer } from "../../../ui/TableActionsContainer";
import { Colors } from "../../../util/Colors";
import { copyTextToClipboard } from "../../../util/copyTextToClipboard";
import { Icon } from "../../../util/Icon";
import { getPatientLink, hasMedicationCheck } from "../../../util/Patient";

const StyledButton = withStyles({
    root: {
        fontWeight: "bold",
        letterSpacing: 1,
        "&:hover": {
            color: Colors.secondary,
        },
    },
})(Button);

type IProps = {
    rows: IPatient[];
    onClickReleaseReport: (patient: IPatient) => void;
    onClickOptimize: (patient: IPatient) => void;
    onClickMedicationCheck: (patient: IPatient) => void;
    onClickMedicationGroups: (patient: IPatient) => void;
    onChangeSort: (column: string) => void;
    onClickDownloadReport: (patient: IPatient) => void;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
};

const getHeaderCells = () => [
    { column: "lastname", label: t("table.heading.lastname") },
    { column: "firstname", label: t("table.heading.firstname") },
    { column: "birthdate", label: t("table.heading.birthdate") },
    { column: "pharmgenetixId", label: t("table.heading.pharmgenetix_id") },
    { column: "reportUploadDate",label: t("table.heading.report") },
    { column: "canOptimize", label: t("table.heading.plan") },
    { label: null },
];

export const PatientsTable = observer(
    ({
        rows,
        onClickOptimize,
        onClickReleaseReport,
        onClickMedicationCheck,
        onClickMedicationGroups,
        onClickDownloadReport,
        onChangeSort,
        orderBy,
        orderDirection,
    }: IProps) => {
        enum ECopyClickEvents {
            patient,
            resetPasswordLink,
        }

        const [analyzeEl, setAnalyzeEl] = React.useState<HTMLButtonElement | null>(null);
        const [copyEl, setCopyEl] = React.useState<HTMLButtonElement | null>(null);
        const [patient, setPatient] = React.useState<IPatient>();

        const handleCloseAnalyzeEl = () => {
            setAnalyzeEl(null);
            setPatient(undefined);
        };

        const handleOpenAnalyzeEl = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAnalyzeEl(event.currentTarget);
        };

        const handleCloseCopyEl = () => {
            setCopyEl(null);
            setPatient(undefined);
        };

        const handleOpenCopyEl = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCopyEl(event.currentTarget);
        };

        const handleMenuItemClick = (action: ECopyClickEvents) => {
            if (patient) {
                handleCloseCopyEl();
                // add timeout, otherwise the copyToClipboard function won't work
                setTimeout(() => {
                    const copyText =
                        action === ECopyClickEvents.resetPasswordLink
                            ? patient.passwordResetLink
                            : getPatientLink(Routes.DOCTOR.ROOT, patient.pharmgenetixId);
                    copyTextToClipboard(copyText);
                    generalStore.successMessage = t("success.copy");
                }, 300);
            }
        };

        return (
            <div style={{ overflow: "auto" }}>
                <Table data-id="patients_table" aria-label="table">
                    <TableHead>
                        <TableRow>
                            {getHeaderCells().map((header, index) => (
                                <SortableTableCell
                                    key={`${header.column}_${index}`}
                                    column={header.column}
                                    label={header.label}
                                    orderBy={orderBy}
                                    orderDirection={orderDirection}
                                    onChangeSort={onChangeSort}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.uid}
                                data-id={`row_${index + 1}`}
                                style={{ backgroundColor: row.reportRead ? undefined : "#E6F7FF" }}
                            >
                                <TableCell data-id="lastname">{row.lastname}</TableCell>
                                <TableCell data-id="firstname">{row.firstname}</TableCell>
                                <TableCell data-id="birthdate">
                                    {moment.utc(row.birthdate).format("DD.MM.YYYY")}
                                </TableCell>
                                <TableCell data-id="pharmgenetix_id">{row.pharmgenetixId}</TableCell>
                                <TableCell data-id="reportdate">
                                    {row.reportUploadDate && moment(row.reportUploadDate).format("DD.MM.YYYY")}
                                </TableCell>
                                <TableCell data-id="plan">
                                    {row.canOptimize ? t("common.plan.totalCare") : t("common.plan.basic")}
                                </TableCell>
                                <TableCell data-id={"actions"} align="right">
                                    <TableActionsContainer>
                                        {row.canOptimize && (
                                            <StyledButton
                                                size="small"
                                                disableRipple
                                                onClick={() => onClickOptimize(row)}
                                                data-id="optimize"
                                            >
                                                {t("table.action.optimize")}
                                            </StyledButton>
                                        )}
                                        <StyledButton
                                            size="small"
                                            disableRipple
                                            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                handleOpenAnalyzeEl(event);
                                                setPatient(row);
                                            }}
                                            data-id="optimize"
                                        >
                                            {t("table.action.analyse")}
                                        </StyledButton>
                                        <Icon
                                            data-id="release_report"
                                            name={row.reportReleased ? "unlocked" : "locked"}
                                            title={
                                                row.reportReleased ? undefined : t("table.action.release_report.title")
                                            }
                                            disabled={row.reportReleased}
                                            hoverColor={Colors.secondary}
                                            onClick={() => onClickReleaseReport(row)}
                                            color={row.reportReleased ? Colors.released : undefined}
                                            disabledColor={Colors.released}
                                        />
                                        <Icon
                                            data-id="copy"
                                            name="copy"
                                            title={t("table.action.copy.patient")}
                                            hoverColor={Colors.secondary}
                                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                setPatient(row);
                                                handleOpenCopyEl(e);
                                            }}
                                        />
                                    </TableActionsContainer>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {analyzeEl && patient ? (
                    <Menu id="menu" anchorEl={analyzeEl} open={Boolean(analyzeEl)} onClose={handleCloseAnalyzeEl}>
                        <MenuItem onClick={() => onClickMedicationGroups(patient)}>
                            <Icon
                                data-id="medication_groups"
                                name="medicationGroups"
                                title={t("table.action.medication_groups.title")}
                                hoverColor={Colors.secondary}
                                style={{ marginRight: 10 }}
                            />
                            {t("table.action.dropdown.medication_group")}
                        </MenuItem>
                        {!patient.canOptimize && hasMedicationCheck(patient) ? (
                            <MenuItem onClick={() => onClickMedicationCheck(patient)}>
                                <Icon
                                    data-id="medication_check"
                                    name="medicationCheck"
                                    title={t("table.action.medication_check.title")}
                                    hoverColor={Colors.secondary}
                                    style={{ marginRight: 10 }}
                                />
                                {t("table.action.dropdown.medication_check")}
                            </MenuItem>
                        ) : null}
                        <MenuItem onClick={() => onClickDownloadReport(patient)}>
                            <Icon
                                name="pdf"
                                style={{ marginRight: 10 }}
                                hoverColor={Colors.secondary}
                                title={t("table.action.download_report.title")}
                                data-id="download_report"
                            />
                            {t("table.action.dropdown.download_report")}
                        </MenuItem>
                    </Menu>
                ) : null}
                {copyEl && patient ? (
                    <Menu id="menu" anchorEl={copyEl} open={Boolean(copyEl)} onClose={handleCloseCopyEl}>
                        <MenuItem onClick={() => handleMenuItemClick(ECopyClickEvents.patient)}>
                            {t("table.action.copy.patient")}
                        </MenuItem>
                    </Menu>
                ) : null}
            </div>
        );
    },
);
