import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { authStore } from "../../stores/AuthStore";
import { NotFoundSite } from "../sites/NotFoundSite";
import { PgxAdminSite } from "../sites/pgxadmin/UserManagementSite";
import { Routes } from "./Routes";
import { PatientSite } from "../sites/patient/PatientSite";
import { DoctorSite } from "../sites/doctor/PatientManagementSite";
import { MedicationAnalysisSite } from "../sites/doctor/MedicationAnalysisSite";
import { AutoOptimizeSite } from "../sites/doctor/AutoOptimizeSite";
import { AutoOptimizeSaveSite } from "../sites/doctor/AutoOptimizeSaveSite";
import { ManualOptimizeSite } from "../sites/doctor/ManualOptimizeSite";
import { MedicationSite } from "../sites/doctor/MedicationSite";
import { NewsSite } from "../sites/doctor/NewsSite";
import { CustomDialog } from "../dialogs/CustomDialog";
import { doctorStore } from "../../stores/DoctorStore";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { newsStore } from "../../stores/NewsStore";
import { API } from "../../network/API";
import { UnreadNewsDialog } from "../dialogs/UnreadNewsDialog";
import { patientStore } from "../../stores/PatientStore";
import { TrainingSite } from "../sites/patient/TrainingSite";

export const AuthorizedRouter = observer((props: any): any => {
    const [showUnreadNewsDialog, setShowUnreadNewsDialog] = React.useState(false);

    const handleUnreadNewsDialogSubmit = async () => {
        try {
            const response = await API.markNewsAsRead();
            authStore.authProfile = response;
            newsStore.markNewsAsRead();
        } catch (error) {
            console.log(error);
        }
    };

    if (!authStore.isRehydrated) {
        return null;
    }

    if (authStore.isAuthenticated) {
        if (authStore.isAdmin) {
            return (
                <Switch>
                    <Route exact path={Routes.PGX_ADMIN.ROOT} render={() => <PgxAdminSite activeTab="doctors" />} />
                    <Route exact path={Routes.PGX_ADMIN.DOCTORS} render={() => <PgxAdminSite activeTab="doctors" />} />
                    <Route exact path={Routes.PGX_ADMIN.NEWS} render={() => <PgxAdminSite activeTab="news" />} />
                    <Route
                        exact
                        path={Routes.PGX_ADMIN.PATIENTS}
                        render={() => <PgxAdminSite activeTab="patients" />}
                    />
                    <Route component={NotFoundSite} />
                </Switch>
            );
        } else if (authStore.isDoctor) {
            return (
                <>
                    <Switch>
                        <Route exact path={Routes.DOCTOR.ROOT} component={DoctorSite} />
                        <Route exact path={Routes.DOCTOR.OPTIMIZE} component={MedicationAnalysisSite} />
                        <Route exact path={Routes.DOCTOR.OPTIMIZE_AUTO} component={AutoOptimizeSite} />
                        <Route exact path={Routes.DOCTOR.OPTIMIZE_AUTO_REPLACE} component={AutoOptimizeSaveSite} />
                        <Route exact path={Routes.DOCTOR.OPTIMIZE_MANUAL} component={ManualOptimizeSite} />
                        <Route path={Routes.DOCTOR.MEDICATION_GROUPS} component={MedicationSite} />
                        <Route path={Routes.DOCTOR.MEDICATION_CHECK} component={MedicationSite} />
                        <Route path={Routes.DOCTOR.NEWS} component={NewsSite} />
                        <Route component={NotFoundSite} />
                    </Switch>
                    {doctorStore.showAppWarningDialog && (
                        <CustomDialog
                            open={doctorStore.showAppWarningDialog}
                            onSubmit={() => {
                                doctorStore.showAppWarningDialog = false;
                                setShowUnreadNewsDialog(true);
                            }}
                            maxWidth="md"
                            title={t("doctor.appUsageWarningDialog.title")}
                            message={<FormattedHTMLMessage id="doctor.appUsageWarningDialog.text" />}
                            buttonText={t("doctor.appUsageWarningDialog.button.text")}
                        />
                    )}
                    {newsStore.hasUnreadNews && (
                        <UnreadNewsDialog
                            unreadNews={newsStore.unreadNews}
                            open={showUnreadNewsDialog}
                            onSubmit={handleUnreadNewsDialogSubmit}
                            onClose={() => {
                                setShowUnreadNewsDialog(false);
                            }}
                        />
                    )}
                </>
            );
        } else if (authStore.isPatient) {
            return (
                <>
                    <Switch>
                        <Route path={Routes.PATIENT.ROOT} component={PatientSite} />
                        <Route component={NotFoundSite} />
                    </Switch>
                    {patientStore.showAppWarningDialog && (
                        <CustomDialog
                            open={patientStore.showAppWarningDialog}
                            onSubmit={() => {
                                patientStore.showAppWarningDialog = false;
                                setShowUnreadNewsDialog(true);
                            }}
                            maxWidth="md"
                            title={t("patient.appUsageWarningDialog.title")}
                            message={<FormattedHTMLMessage id="patient.appUsageWarningDialog.text" />}
                            buttonText={t("patient.appUsageWarningDialog.button.text")}
                        />
                    )}
                    {newsStore.hasUnreadNews && (
                        <UnreadNewsDialog
                            unreadNews={newsStore.unreadNews}
                            open={showUnreadNewsDialog}
                            onSubmit={handleUnreadNewsDialogSubmit}
                            onClose={() => {
                                setShowUnreadNewsDialog(false);
                            }}
                        />
                    )}
                </>
            );
        } else if (authStore.userProfile?.scope.includes("patientUncompletedTraining")) {
            return (
                <Switch>
                    <Route path={Routes.PATIENT.TRAINING} component={TrainingSite} exact />
                    <Redirect to={Routes.PATIENT.TRAINING} />
                </Switch>
            );
        }
    }

    return (
        <Redirect
            to={{
                pathname: Routes.ROOT,
                state: { from: props.location },
            }}
        />
    );
});
