import { observer } from "mobx-react";
import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { generalStore } from "../../stores/GeneralStore";
import { ForgotPasswordSite } from "../sites/ForgotPasswordSite";
import { LoginSite } from "../sites/LoginSite";
import { NotFoundSite } from "../sites/NotFoundSite";
import { SetPasswordSite } from "../sites/SetPasswordSite";
import { ManualsSite } from "../sites/ManualsSite";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { AuthorizedRouter } from "./AuthorizedRouter";
import { history } from "./history";
import { NoAuthOnlyRoute } from "./NoAuthOnlyRoute";
import { Routes } from "./Routes";
import { RoutingManager } from "./RoutingManager";
import { ErrorToast } from "../ui/ErrorToast";
import { SuccessToast } from "../ui/SuccessToast";

export const AppRouter = observer(() => (
    <>
        <Router history={history}>
            <RoutingManager>
                <Switch>
                    <NoAuthOnlyRoute exact path={Routes.ROOT} component={LoginSite} />
                    <NoAuthOnlyRoute exact path={Routes.FORGOT_PASSWORD} component={ForgotPasswordSite} />
                    <NoAuthOnlyRoute exact path={Routes.SET_PASSWORD} component={SetPasswordSite} />
                    <Route exact path={Routes.MANUALS} component={ManualsSite} />
                    <Route component={AuthorizedRouter} />
                    <Route component={NotFoundSite} />
                </Switch>
            </RoutingManager>
        </Router>
        {generalStore.isLoading && <LoadingOverlay />}
        <ErrorToast />
        <SuccessToast />
    </>
));
