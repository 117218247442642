import { uniqBy } from "lodash";
import { action, computed, observable } from "mobx";
import { create, persist } from "mobx-persist";
import { getTestedGroups, hasMedicationCheck } from "../components/util/Patient";
import { t } from "../i18n/util";
import { API, isUnauthorizedError } from "../network/API";
import { IMedication, IPatient, ISubstance, ISubstanceId } from "../types";
import { authStore } from "./AuthStore";
import { generalStore } from "./GeneralStore";

export type ISingleMedGroup = {
    name: string;
    substances: ISubstance[];
};

type PatientPlan = "single" | "base" | "totalCare";

class PatientStore {
    @persist @observable showAppWarningDialog = true;
    @observable isRehydrated = false;
    @observable patientPlan: PatientPlan = "base";
    @observable groups: ISingleMedGroup[] = [];
    @observable substanceCalculateResults: { [substanceId in ISubstanceId]: ISubstance } | null = null;
    @observable medicationAfterManualReplace: IMedication | null = null;
    @observable isLoading = false;

    @computed get showMedicationCheck() {
        const patient = authStore.userProfile?.user as IPatient | undefined;
        return patient ? hasMedicationCheck(patient) : false;
    }

    @action wipe() {
        this.patientPlan = "base";
        this.groups = [];
        this.substanceCalculateResults = null;
        this.medicationAfterManualReplace = null;
    }

    @action async loadGroups(patient?: IPatient | null) {
        try {
            const user = authStore.isDoctor ? patient : (authStore.userProfile?.user as IPatient);

            if (!user) {
                throw new Error();
            }

            if (authStore.isDoctor) {
                this.groups = []; // Reset groups in case of error, otherwise maybe groups of a different patient are shown
                this.substanceCalculateResults = null;
            } else if (authStore.isPatient) {
                if (!user.reportReleased) {
                    this.groups = [];
                    this.substanceCalculateResults = null;
                    return;
                }
            }

            this.isLoading = true;

            const result = await API.getMedicationGroups(patient?.uid);

            const resultSubstances = result.combinations[0].medication;

            const uniqSubstances = uniqBy(resultSubstances, substance => substance.id);
            const mappedSubstances = uniqSubstances.reduce<{ [substanceId in ISubstanceId]: ISubstance }>(
                (accumulator, substance) => ({
                    ...accumulator,
                    [substance.id]: substance,
                }),
                {},
            );

            this.substanceCalculateResults = mappedSubstances;

            const testedGroups = getTestedGroups(user);

            const groupNames = Object.keys(testedGroups);

            const groupsWithSubstances = groupNames.map(groupName => ({
                name: groupName,
                substances: testedGroups[groupName].map(substanceId => mappedSubstances[substanceId]),
            }));

            this.groups = groupsWithSubstances;
        } catch (error) {
            this.groups = [];
            this.substanceCalculateResults = null;
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.loadSingleMedGroups");
            }
            console.error(error);
        }

        this.isLoading = false;
    }
}

const hydrate = create({
    storage: require("localforage"),
});

const patientStore = new PatientStore();

hydrate("doctor", patientStore)
    .then(() => {
        patientStore.isRehydrated = true;
    })
    .catch(error => {
        console.error(error);
    });

export { patientStore };
