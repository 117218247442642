import * as React from "react";
import { t } from "../../i18n/util";
import { observer } from "mobx-react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { CustomInputField } from "../ui/CustomInputField";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import styled from "styled-components";
import { authStore } from "../../stores/AuthStore";
import { Colors } from "../util/Colors";

const Instruction = styled.p`
    margin: 14px auto 35px
`

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${Colors.danger};
    font-size: 16px;
    padding-bottom: 8px;
`;

export const VerificationForm = observer(() => {
    const handleSubmit = async (values: { code: string; rememberDevice: boolean }) => {
        if (!values.code) {
            return
        }

        authStore.verifyCode(values.code, values.rememberDevice)
    };

    return (
        <>
            <h4>{t("mfa.form_header")}</h4>
            <Instruction>{t("mfa.instruction")}</Instruction>

            <Formik
                initialValues={{
                    code: "",
                    rememberDevice: false,
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                    code: Yup.string().required(t("mfa.code_error")),
                    rememberDevice: Yup.boolean()
                })}
                validateOnBlur
                validateOnChange
            >
                {({ errors, touched, isSubmitting, values }) => (
                    <Form>
                        <Field
                            data-id="mfa-code"
                            component={CustomInputField}
                            label={t("mfa.code_label")}
                            name="code"
                            required
                            errorMessage={errors.code || authStore.error }
                            isTouched={touched.code}
                        />

                        <Field
                            type="checkbox"
                            as={FormControlLabel}
                            control={<Checkbox/>}
                            label={t("mfa.remember_device_label")}
                            name="rememberDevice"
                        />

                        <ErrorContainer>{ authStore.error }</ErrorContainer>

                        <Button
                            data-id="mfa_submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting || !values.code}
                            type="submit"
                            size="large"
                        >
                            {t("mfa.submit")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
});
