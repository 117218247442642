/* eslint-disable no-throw-literal */

import { IDoctorValues } from "../components/forms/DoctorForm";
import { IPatientValues } from "../components/forms/PatientForm";
import * as Config from "../config";
import { ILocales } from "../i18n/ILocales";
import { t } from "../i18n/util";
import { authStore, ILoginResponse } from "../stores/AuthStore";
import {
    IDoctor,
    IGetMedicationResponse,
    IGetSubstancesResponse,
    IAnalysisHistory,
    IPatient,
    IPostCalculateResponse,
    IPostMedicationResponse,
    IPostOptimizeResponse,
    ISelectedGroups,
    IStatusResponse,
    ISubstanceId,
    IPostDoctorSuggestion,
    IPostDoctorSuggestionResponse,
    IGetNewsOptions,
    IGetNewsResponse,
    INewsValues,
    IGetAuthProfileResponse,
    IDeleteUserResponse,
    ISubstance,
    SummaryRequestDoctor,
    Question,
} from "../types";

export const STATUS_CODE_UNAUTHORIZED = 401;

const getAuthHeaders = () => ({
    Authorization: `Bearer ${authStore.credentials?.accessToken}`,
});

export const isUnauthorizedError = (error: any) => error.statusCode === STATUS_CODE_UNAUTHORIZED;

const getQueryString = (options: { [key: string]: any }) => {
    const queryString = Object.keys(options)
        .map(key => `${key}=${encodeURIComponent(options[key])}`)
        .join("&");

    return queryString ? `?${queryString}` : "";
};

const request = async ({
    path,
    method,
    body,
    appendAuthToken,
    errors,
    blob,
    credentials,
    headers = {},
}: {
    path: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    body?: any;
    appendAuthToken?: boolean;
    errors?: { [code: string]: string };
    blob?: boolean;
    headers?: {
        [header: string]: string;
    };
    credentials?: "omit" | "same-origin" | "include";
}) => {
    try {
        const response = await fetch(`${Config.API_BASE_URL}/api/v1/${path}`, {
            method,
            headers: {
                ...(typeof body === "string" ? { "Content-Type": "application/json" } : {}),
                ...(appendAuthToken ? getAuthHeaders() : {}),
                ...headers,
            },
            credentials,
            ...(body ? { body } : {}),
        });

        if (!response.ok) {
            throw {
                statusCode: response.status,
                statusText: errors ? errors[response.status] : response.statusText,
            };
        }

        return blob ? response.blob() : response.json();
    } catch (error) {
        if (isUnauthorizedError(error)) {
            authStore.logout();
        }

        throw error;
    }
};

export const API = {
    loginWithPassword(options: { username: string; password: string }): Promise<ILoginResponse> {
        return request({
            path: "auth/login",
            method: "POST",
            body: JSON.stringify({ ...options, username: options.username.toLowerCase() }),
            credentials: "include",
        });
    },
    verifyCode(data: { code: string; token: string; uid: string; rememberDevice: boolean }) {
        return request({
            path: "auth/login/verify",
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
        });
    },
    resetPassword(options: { username: string }): Promise<{ success: boolean }> {
        return request({
            path: "auth/forgot-password",
            method: "POST",
            body: JSON.stringify({ ...options, username: options.username.toLowerCase() }),
        });
    },
    setPassword(options: { token: string; password: string }): Promise<{ success: boolean; message: string }> {
        const errors = {
            "400": t("screen.set_password.form.error_invalid_token"), // invalidPasswordResetPayload
            "403": "", // userDeactivated
            "404": t("screen.set_password.form.error_expired_token"), // invalidPasswordResetToken
            "409": t("screen.set_password.form.error_weak_password"), //weakPassword
        };

        return request({
            path: "auth/forgot-password/complete",
            method: "POST",
            body: JSON.stringify(options),
            appendAuthToken: false,
            errors,
        });
    },
    checkTokenValidity(options: { token: string }): Promise<{ valid: boolean }> {
        return request({
            path: "auth/forgot-password/check-validity",
            method: "POST",
            body: JSON.stringify(options),
            appendAuthToken: false,
        });
    },
    resendEmail(options: { uid: string }) {
        return request({
            path: "auth/resend-mail",
            method: "POST",
            body: JSON.stringify(options),
            appendAuthToken: true,
        });
    },
    addDoctor(options: any): Promise<IDoctor> {
        const errors = {
            "409": t("error.emailAlreadyInUse"),
            "400": t("error.addDoctor"),
        };
        return request({
            path: "doctors",
            method: "POST",
            body: JSON.stringify(options),
            appendAuthToken: true,
            errors,
        });
    },
    editDoctor(uid: string, options: Partial<IDoctorValues>): Promise<IDoctor> {
        const errors = {
            "409": t("error.emailAlreadyInUse"),
            "400": t("error.editDoctor"),
        };
        return request({
            path: `doctors/${uid}`,
            method: "PUT",
            body: JSON.stringify(options),
            appendAuthToken: true,
            errors,
        });
    },
    getDoctors(
        options: {
            uid?: string;
            offset?: number;
            limit?: number;
            order?: "asc" | "desc";
            orderBy?: string;
            keyword?: string;
        } = {},
    ): Promise<{
        results: IDoctor[];
        offset: number;
        limit: number;
        total: number;
    }> {
        return request({ path: `doctors${getQueryString(options)}`, method: "GET", appendAuthToken: true });
    },
    getDoctorsCsv(
        options: {
            uid?: string;
            offset?: number;
            limit?: number;
            order?: "asc" | "desc";
            orderBy?: string;
            keyword?: string;
        } = {},
    ) {
        return request({
            path: `doctors${getQueryString(options)}`,
            method: "GET",
            appendAuthToken: true,
            headers: { Accept: "text/csv" },
            blob: true,
        });
    },
    getDoctor(uid: string): Promise<IDoctor> {
        return request({ path: `doctors/${uid}`, method: "GET", appendAuthToken: true });
    },
    addPatient(options: IPatientValues): Promise<IPatient> {
        const formData = new FormData();
        const { pgxFile, pdfFile, ...jsonData } = options;

        const errors = {
            "409": t("error.emailAlreadyInUse"),
            "400": t("error.addPatient"),
        };

        formData.append("jsonData", JSON.stringify(jsonData));

        if (pgxFile) {
            formData.append("pgxFile", pgxFile, pgxFile.name);
        }

        if (pdfFile) {
            formData.append("pdfFile", pdfFile, pdfFile.name);
        }

        return request({ path: "patients", method: "POST", body: formData, appendAuthToken: true, errors });
    },
    editPatient(uid: string, options: Partial<IPatientValues>): Promise<IPatient> {
        const formData = new FormData();
        const { pgxFile, pdfFile, ...jsonData } = options;

        const errors = {
            "409": t("error.emailAlreadyInUse"),
            "400": t("error.addPatient"),
        };

        formData.append("jsonData", JSON.stringify(jsonData));

        if (pgxFile) {
            formData.append("pgxFile", pgxFile, pgxFile.name);
        }

        if (pdfFile) {
            formData.append("pdfFile", pdfFile, pdfFile.name);
        }

        return request({
            path: `patients/${uid}`,
            method: "PUT",
            body: formData,
            appendAuthToken: true,
            errors,
        });
    },
    getPatients(
        options: {
            uid?: string;
            offset?: number;
            limit?: number;
            order?: "asc" | "desc";
            orderBy?: string;
            keyword?: string;
            doctorUid?: string;
        } = {},
    ): Promise<{
        results: IPatient[];
        offset: number;
        limit: number;
        total: number;
        patientsWithoutReleasedReport: number;
    }> {
        return request({ path: `patients${getQueryString(options)}`, method: "GET", appendAuthToken: true });
    },
    getPatientsCsv(
        options: {
            uid?: string;
            offset?: number;
            limit?: number;
            order?: "asc" | "desc";
            orderBy?: string;
            keyword?: string;
            doctorUid?: string;
        } = {},
    ) {
        return request({
            path: `patients${getQueryString(options)}`,
            method: "GET",
            appendAuthToken: true,
            headers: { Accept: "text/csv" },
            blob: true,
        });
    },
    getPatient(uid: string): Promise<IPatient> {
        return request({ path: `patients/${uid}`, method: "GET", appendAuthToken: true });
    },
    getMedication(uid: string): Promise<IGetMedicationResponse> {
        return request({ path: `patients/${uid}/medication`, method: "GET", appendAuthToken: true });
    },
    getMedicationHistory(uid: string): Promise<IAnalysisHistory> {
        return request({ path: `patients/${uid}/medication/history`, method: "GET", appendAuthToken: true });
    },
    saveMedication(uid: string, substances: ISubstanceId[], remarks: string): Promise<IPostMedicationResponse> {
        return request({
            path: `patients/${uid}/medication`,
            method: "POST",
            body: JSON.stringify({ substances, remarks }),
            appendAuthToken: true,
        });
    },
    releaseReport(uid: string, reportReleased: boolean) {
        return request({
            path: `patients/${uid}/releaseReport`,
            method: "PUT",
            body: JSON.stringify({ reportReleased }),
            appendAuthToken: true,
        });
    },
    getTrainingQuestions(): Promise<{ questions: Question[] }> {
        return request({
            path: `patients/training/questions`,
            method: "GET",
            appendAuthToken: true,
        });
    },
    verifyAnsweredQuestions(answeredQuestions: Question[]) {
        return request({
            path: `patients/training/verify`,
            method: "POST",
            body: JSON.stringify({ answeredQuestions }),
            appendAuthToken: true,
        });
    },
    getSubstances(): Promise<IGetSubstancesResponse> {
        return request({ path: "substances", method: "GET", appendAuthToken: true });
    },
    getVersions(): Promise<IStatusResponse> {
        return request({ path: "status", method: "GET", appendAuthToken: true });
    },
    calculate(
        uid: string,
        substanceIDs: ISubstanceId[] | (ISubstanceId | ISubstanceId[])[],
        wantInteraction: boolean,
    ): Promise<IPostCalculateResponse> {
        return request({
            path: "calculate",
            method: "POST",
            body: JSON.stringify({ patientUid: uid, substanceIDs, wantInteraction }),
            appendAuthToken: true,
        });
    },
    optimize(
        uid: string,
        ignoredIDs: ISubstanceId[],
        substanceIDs: ISubstanceId[],
        groups?: ISelectedGroups,
    ): Promise<IPostOptimizeResponse> {
        return request({
            path: "optimize",
            method: "POST",
            body: JSON.stringify({ patientUid: uid, ignoredIDs, substanceIDs, groups }),
            appendAuthToken: true,
        });
    },
    getMedicationGroups(uid?: string): Promise<IPostCalculateResponse> {
        return request({
            path: `patients/singleMedCalculate${uid ? `?uid=${uid}` : ""}`,
            method: "GET",
            appendAuthToken: true,
        });
    },
    getPdfReport(uid: string): Promise<Blob> {
        return request({ path: `patients/${uid}/pdfReport`, method: "GET", appendAuthToken: true, blob: true });
    },

    postDoctorSuggestion(data: IPostDoctorSuggestion, uid?: string): Promise<IPostDoctorSuggestionResponse> {
        return request({
            path: `patients/${uid}/doctors/suggestion`,
            method: "POST",
            body: JSON.stringify(data),
            appendAuthToken: true,
        });
    },

    deleteDoctorFromPatient(uid: string, doctorUids: string[]): Promise<IPatient> {
        return request({
            path: `patients/${uid}/doctors`,
            method: "DELETE",
            body: JSON.stringify({ doctorUids }),
            appendAuthToken: true,
        });
    },

    assignDoctorsToPatient(uid: string, doctorUids: string[]): Promise<IPatient> {
        return request({
            path: `patients/${uid}/doctors`,
            method: "POST",
            body: JSON.stringify({ doctorUids }),
            appendAuthToken: true,
        });
    },
    deletePatient(uid: string): Promise<IDeleteUserResponse> {
        return request({
            path: `patients/${uid}`,
            method: "DELETE",
            appendAuthToken: true,
        });
    },
    getNews(options: IGetNewsOptions = {}): Promise<IGetNewsResponse> {
        return request({
            path: `cms/news${getQueryString(options)}`,
            method: "GET",
            appendAuthToken: true,
        });
    },
    putNews(data: INewsValues): Promise<INewsValues> {
        return request({
            path: `cms/news`,
            method: "PUT",
            body: JSON.stringify(data),
            appendAuthToken: true,
        });
    },
    deleteNews(uid: string) {
        return request({
            path: `cms/news/${uid}`,
            method: "DELETE",
            appendAuthToken: true,
        });
    },
    deleteDoctor(uid: string): Promise<IDeleteUserResponse> {
        return request({
            path: `doctors/${uid}`,
            method: "DELETE",
            appendAuthToken: true,
        });
    },
    getAuthProfile(): Promise<IGetAuthProfileResponse> {
        return request({
            path: `auth/profile`,
            method: "GET",
            appendAuthToken: true,
        });
    },
    markNewsAsRead(): Promise<IGetAuthProfileResponse["profile"]> {
        return request({
            path: `user/markNewsRead`,
            method: "POST",
            appendAuthToken: true,
        });
    },
    setLanguage(language: ILocales) {
        return request({
            path: "user/setLanguage",
            method: "POST",
            appendAuthToken: true,
            body: JSON.stringify({ language }),
        });
    },
    markReportAsRead(patientUids: string[]) {
        return request({
            path: `patients/read`,
            method: "POST",
            body: JSON.stringify({ patientUids }),
            appendAuthToken: true,
        });
    },
    getSummary(uid: string, data: { doctor: SummaryRequestDoctor; expectedMedication: ISubstance[] }) {
        return request({
            path: `patients/${uid}/summary`,
            method: "POST",
            appendAuthToken: true,
            blob: true,
            body: JSON.stringify(data),
        });
    },
    patchUser(data: { uid: string; mfaEnabled: boolean }) {
        return request({
            path: "user",
            method: "PATCH",
            appendAuthToken: true,
            body: JSON.stringify(data),
        });
    },
};
