import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, withStyles } from "@material-ui/core";
import { times } from "lodash";
import * as React from "react";
import styled from "styled-components";
import { IMessageIDS, t } from "../../../../i18n/util";
import { IEnzyme, IMedication, ISubstanceId } from "../../../../types";
import { BadgeCollection } from "../../../ui/BadgeCollection";
import { Badge } from "../../../util/Badge";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { getTranslation } from "../../../util/Optimizer";

enum EEnzymeCategory {
    substrate = "substrate",
    transporter = "transporter",
    link = "link",
}

type IEnzymeWithCategory = IEnzyme & {
    category: EEnzymeCategory;
};

const withCategory = (enzyme: IEnzyme, category: EEnzymeCategory): IEnzymeWithCategory => {
    const enzymeWithCategory = enzyme as IEnzymeWithCategory;
    enzymeWithCategory.category = category;
    return enzymeWithCategory;
};

const StyledTableCell = withStyles({
    root: {
        paddingRight: 0,
        minWidth: 200,
        maxWidth: 200,
    },
})(TableCell);

const NoBorderTableCell = withStyles({
    root: {
        borderBottom: "none",
    },
})(TableCell);

const ButtonContainer = styled.div`
    display: inline-flex;
    align-items: end;
    flex-direction: column;
`;

const ButtonText = styled.span`
    margin-right: 8px;
`;

const LabeledBadgeContainer = styled.div`
    display: inline-flex;
    align-items: center;
`;

const BadgeLabel = styled.span`
    font-size: 14px;
    color: #878787;
    margin-left: 12px;
`;

const LabeledBadge = React.forwardRef<
    HTMLDivElement,
    { isHighlighted: boolean; enzyme: IEnzyme; isEnzyme: boolean; [otherProp: string]: any }
>(({ isHighlighted, enzyme, isEnzyme, ...props }, ref: any) => (
    <LabeledBadgeContainer ref={ref} {...props}>
        {enzyme.missing ? (
            <Badge color={isHighlighted ? "white" : "gray"} value="unknown" isEnzyme={isEnzyme} />
        ) : (
            <Badge color={isHighlighted ? "white" : "gray"} value={enzyme.fc} isEnzyme={isEnzyme} />
        )}
        <BadgeLabel>{enzyme.name}</BadgeLabel>
    </LabeledBadgeContainer>
));

const SingleEnzyme = ({
    enzyme,
    isHighlighted,
    isEnzyme,
}: {
    enzyme: IEnzyme;
    isHighlighted: boolean;
    isEnzyme: boolean;
}) => {
    return (
        <>
            <Tooltip
                arrow
                placement="top"
                title={
                    enzyme.weight ? (
                        <div>
                            {t("common.enzyme.weight")}:<div>{t(weightToText[enzyme.weight])}</div>
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <LabeledBadge isHighlighted={isHighlighted} enzyme={enzyme} isEnzyme={isEnzyme} />
            </Tooltip>
            {enzyme.name === "CYP3A5" && (
                <Tooltip arrow placement="top" title={t("screen.doctor.manual_optimize.enzyme.cyp3a5.info")}>
                    <div style={{ display: "inline-flex", alignItems: "center", marginLeft: "12px" }}>
                        <Icon name="info" />
                    </div>
                </Tooltip>
            )}
        </>
    );
};

const InteractionEnzyme = ({ enzyme, isHighlighted }: { enzyme: IEnzyme; isHighlighted: boolean }) => {
    return (
        <>
            <Tooltip
                arrow
                placement="top"
                title={
                    enzyme.substances?.length > 0 ? (
                        <div>
                            {t("common.enzyme.interaction_with")}:
                            {enzyme.substances.map(substanceId => (
                                <div key={substanceId}>{getTranslation(substanceId)}</div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <LabeledBadge isHighlighted={isHighlighted} enzyme={enzyme} />
            </Tooltip>
            {enzyme.name === "CYP3A5" && (
                <Tooltip arrow placement="top" title={t("screen.doctor.manual_optimize.enzyme.cyp3a5.info")}>
                    <div style={{ display: "inline-flex", alignItems: "center", marginLeft: "12px" }}>
                        <Icon name="info" />
                    </div>
                </Tooltip>
            )}
        </>
    );
};

const weightToText: { [weight: string]: IMessageIDS } = {
    1: "common.enzyme.weight.high",
    2: "common.enzyme.weight.medium",
    3: "common.enzyme.weight.low",
};

type IProps = {
    data?: IMedication;
    selectedSubstanceId: ISubstanceId;
    canShowDetailInfo?: boolean;
    showLegend?: boolean;
    onShowLegendToggled: () => void;
};

export const InteractionsPreviewTable = ({
    data,
    selectedSubstanceId,
    canShowDetailInfo,
    showLegend,
    onShowLegendToggled,
}: IProps) => {
    const [showDetailInfo, setShowDetailInfo] = React.useState(false);

    const handleToggleShowDetailInfo = () => {
        setShowDetailInfo(!showDetailInfo);
    };

    if (!data) {
        return null;
    }

    return (
        <Table data-id="interactions_preview_table" aria-label="table">
            <TableHead>
                <TableRow>
                    <TableCell>{t("table.heading.active_agent")}</TableCell>
                    <TableCell>{t("table.heading.single_medication")}</TableCell>
                    <TableCell>{t("table.heading.interaction")}</TableCell>
                    <StyledTableCell align="right">
                        <ButtonContainer>
                            {canShowDetailInfo && (
                                <Button
                                    size="small"
                                    onClick={handleToggleShowDetailInfo}
                                    disableRipple
                                    style={{ marginBottom: "2px", height: "auto" }}
                                >
                                    <ButtonText>
                                        {showDetailInfo
                                            ? t(
                                                  "screen.doctor.manual_optimize.interaction_preview.show_less_information",
                                              )
                                            : t(
                                                  "screen.doctor.manual_optimize.interaction_preview.show_more_information",
                                              )}
                                    </ButtonText>
                                    <Icon name="info" color="currentColor" />
                                </Button>
                            )}
                            <Button size="small" onClick={onShowLegendToggled} disableRipple style={{ height: "auto" }}>
                                <ButtonText>
                                    {showLegend
                                        ? t("screen.doctor.manual_optimize.interaction_preview.hide_legend")
                                        : t("screen.doctor.manual_optimize.interaction_preview.show_legend")}
                                </ButtonText>
                                <Icon name="info" color="currentColor" />
                            </Button>
                        </ButtonContainer>
                    </StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.medication.map((substance, index) => {
                    const isHighlighted = substance.id === selectedSubstanceId;
                    const interactionEnzymes = substance.interaction?.enzymes || [];
                    const substrateEnzymes = (substance.substrate?.enzymes || []).map(e =>
                        withCategory(e, EEnzymeCategory.substrate),
                    );
                    const transportEnzymes = (substance.transporter?.enzymes || []).map(e =>
                        withCategory(e, EEnzymeCategory.transporter),
                    );
                    const inhibitorEnzymes = (substance.link?.enzymes || []).map(e =>
                        withCategory(e, EEnzymeCategory.link),
                    );

                    const singleMedicationEnzymes = substrateEnzymes.concat(transportEnzymes).concat(inhibitorEnzymes);
                    singleMedicationEnzymes.sort((a, b) => (a.weight < b.weight ? -1 : 1));

                    const maxEnzymeRows = Math.max(interactionEnzymes.length, singleMedicationEnzymes.length);

                    const TableCellComponent = showDetailInfo && maxEnzymeRows > 0 ? NoBorderTableCell : TableCell;

                    return (
                        <React.Fragment key={substance.id}>
                            <TableRow
                                data-id={`row_${index + 1}`}
                                style={{
                                    backgroundColor: isHighlighted ? Colors.background : undefined,
                                }}
                            >
                                <TableCellComponent data-id="active_agent">
                                    <b>{getTranslation(substance.id)}</b>
                                </TableCellComponent>
                                <TableCellComponent data-id="single_medication">
                                    <BadgeCollection>
                                        <Badge value={substance.single.fc} />
                                        {substance.single.warnTL && (
                                            <Badge
                                                value="warning"
                                                color={isHighlighted ? "white" : "gray"}
                                                tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                            />
                                        )}
                                    </BadgeCollection>
                                </TableCellComponent>
                                <TableCellComponent data-id="interaction" colSpan={2}>
                                    {substance.interaction && (
                                        <BadgeCollection>
                                            <Badge value={substance.interaction.fc} />
                                            {substance.single.warnTL && (
                                                <Badge
                                                    value="warning"
                                                    color={isHighlighted ? "white" : "gray"}
                                                    tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                                />
                                            )}
                                        </BadgeCollection>
                                    )}
                                </TableCellComponent>
                            </TableRow>
                            {showDetailInfo &&
                                times(maxEnzymeRows, enzymeIndex => {
                                    const EnzymeTableCellComponent =
                                        maxEnzymeRows === enzymeIndex + 1 ? TableCell : NoBorderTableCell;

                                    const singleEnzyme = singleMedicationEnzymes[enzymeIndex];
                                    const interactionEnzyme = interactionEnzymes[enzymeIndex];

                                    return (
                                        <TableRow
                                            data-id={`row_${index + 1}_enzyme_row_${enzymeIndex + 1}`}
                                            key={`${singleEnzyme?.name ?? ""}_${interactionEnzyme?.name ?? ""}`}
                                            style={{
                                                backgroundColor: isHighlighted ? Colors.background : undefined,
                                            }}
                                        >
                                            <EnzymeTableCellComponent>
                                                {substance.single.prodrug && enzymeIndex === 0 && (
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title={t("screen.doctor.manual_optimize.prodrug.text")}
                                                    >
                                                        <span>
                                                            <span style={{ marginRight: "5px" }}>
                                                                {t("screen.doctor.manual_optimize.prodrug.title")}
                                                            </span>
                                                            <Icon name="info" color="currentColor" />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </EnzymeTableCellComponent>
                                            <EnzymeTableCellComponent data-id="single_medication">
                                                {singleEnzyme && (
                                                    <SingleEnzyme
                                                        enzyme={singleEnzyme}
                                                        isHighlighted={isHighlighted}
                                                        isEnzyme={singleEnzyme.category === EEnzymeCategory.substrate}
                                                    />
                                                )}
                                            </EnzymeTableCellComponent>
                                            <EnzymeTableCellComponent data-id="interaction" colSpan={2}>
                                                {interactionEnzyme && (
                                                    <InteractionEnzyme
                                                        enzyme={interactionEnzyme}
                                                        isHighlighted={isHighlighted}
                                                    />
                                                )}
                                            </EnzymeTableCellComponent>
                                        </TableRow>
                                    );
                                })}
                        </React.Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};
